@import "common";
@import "newsletter-popup";
@import '../../common/sass/custom-modal';

/* Hide the login modal on large screen */
@include media-breakpoint-up(lg) {
    .login-modal.show {
        opacity: 0;
        pointer-events: none;
    }

    .login-modal.show + .custom-modal-backdrop {
        opacity: 0;
        pointer-events: none;
    }

    html, body {
        &.no-scroll-login {
            overflow: visible;
        }
    }
}

.custom-hr {
    margin-top: 2.9rem;
    margin-bottom: 3rem;
}

.card .card-img-top {
    /*
        Fix distorted card image issue in IE:
        https://github.com/twbs/bootstrap/issues/21885
    */
    min-height: 0.01px;
}

#top-heading-container {
    margin-top: 21px;
    margin-bottom: 19px;

    @media (max-width: 414px) {
        h1 {
            font-size: 1rem;
        }
    }

    @media (min-width: 415px) and (max-width: 991px) {
        padding-left: 20px;
        padding-right: 20px;

        h1 {
            font-size: 1.2rem;
        }
    }

    @media (min-width: 992px) {
        margin-top: 2.2rem;
        margin-bottom: 2.05rem;

        .login-register-buttons-container {
            display: none;
        }
    }

    .register-link {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .small-screen .btn {
        width: calc(50% - 40px);
        min-width: 100px;
        max-width: 180px;
        border-width: 2px;
        margin: 0 1px;
        font-size: .8rem;
        padding-top: 2px;
        padding-bottom: 4px;
        font-weight: 600;
    }

    .login-register-buttons-container {
        margin: 2px 0 0 0;
    }
}

#middle-heading-container {
    margin-top: 3.6rem;
    margin-bottom: 3.2rem;

    h2 {
        font-size: 2.3rem;
        color: var(--text-dark);

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }
}

#login-container {
    @media (max-width: 1284px) {
        width: 100%;
        max-width: 100%;
    }
}

#login-register-cards {
    width: 310px;
    position: absolute;
    top: -22px;

    @media (min-width: 0) {
        display: none;
    }

    @media (min-width: 992px) {
        display: block;
        right: 15px;
    }

    @media (min-width: 1285px) {
        right: -5%;
    }

    @media (min-width: 1305px) {
        right: -5%;
    }

    z-index: 1000;

    .card {
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16) !important;
    }

    .card-header {
        color: var(--text-dark);
        background: none;
        border: none;
        font-size: 1.2rem;
        font-weight: 700;
        padding-top: 1.4rem;
        padding-bottom: 0;
        margin-top: 1px;
        margin-bottom: -1px;
    }

    .card-sub-header {
        color: var(--text-dark);
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.3rem;
    }

    .forgot-password-link-container {
        margin-top: 10px;
        margin-bottom: -2px;
    }

    .btn {
        font-weight: 600;
    }
}

#resource-gallery-link-container {
    margin-top: 35px;
    margin-bottom: 33px;
}

#featured-resources-container {
    .card {
        border: none;
    }

    .card-title {
        font-size: 0.95rem;
        font-weight: 600;
        line-height: 1.3rem;
        color: var(--text-dark);
    }

    .card-body {
        padding: .8rem .5rem;
    }

    @media (max-width: 575px) {
        &.row {
            margin-right: -8px;
            margin-left: -8px;
        }

        .col-6 {
            padding-right: 6px;
            padding-left: 6px;
        }
    }
}

.callout-link {
    font-size: 1.3rem;

    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
    }
}

#main-features-container {
    @include media-breakpoint-down(xl) {
        .card-deck {
            text-align: center;
            max-width: 620px;
            margin: 0 auto;

            @include media-breakpoint-up(md) {
                max-width: 640px;
            }
        }
    }

    .card {
        width: 100%;
        border: none;

        @include media-breakpoint-down(xl) {
            flex: auto;
            margin: 0 0 40px 0;
        }
    }

    @include media-breakpoint-down(xl) {
        .card:last-of-type {
            margin-bottom: 10px;
        }
    }

    .card-img-top {
        max-width: 160px;
        max-height: 160px;
        margin: 0 auto;
        box-shadow: 0;
        transition: transform .2s ease;

        @include media-breakpoint-up(sm) {
            max-width: 180px;
            max-height: 180px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 200px;
            max-height: 200px;
        }
    }

    .card-title {
        font-size: 1.4rem;
        margin: 1.35rem 0 1.2rem 0;
        font-weight: 700;
        color: var(--text-dark);
    }

    .card-text {
        font-size: .95rem;
        padding: 0 15px 0 30px;
        color: var(--text-regular);
        line-height: 1.6rem;

        @include media-breakpoint-down(md) {
            text-align: center;
            padding: 0 10px;
        }
    }
}

.content-callout-container {
    margin-top: 80px;
    margin-bottom: 85px;
}

#other-features-container {
    margin-top: 34px;
    padding: 37px 0 56px 0;
    background: var(--background-gray-light);

    .card {
        border: none;
        background: none;
    }

    .card-header {
        border: none;
        background: none;
        padding-left: 0;

        .btn {
            text-align: left;
            font-size: 1.1rem;
            line-height: .3rem;
            font-weight: 700;
            color: var(--text-dark);
            transition: font-size .2s ease;

            svg {
                width: 26px;
                height: 26px;
                margin: -3px 0 0 -4px;
                transition: transform .15s;
            }

            &:not(.collapsed) svg {
                transform: rotate(90deg);
            }
        }

        .btn:hover, .btn:focus {
            text-decoration: none;
        }

        @include media-breakpoint-up(sm) {
            .btn {
                font-size: 1.33rem;
                transition: font-size .2s ease;
            }

            .btn:hover {
                font-size: 1.36rem;
            }
        }
    }

    .card-body {
        font-size: .95rem;
        color: var(--text-regular);
    }

    @include media-breakpoint-down(sm) {
        background: none;
        padding: 0;

        .other-features {
            padding-left: 0;
            padding-right: 0;
        }

        .card-header {
            padding: 0;

            .btn {
                width: 100%;
                padding: 1.75rem 1.25rem;

                i {
                    position: absolute;
                    right: 4px;
                    top: 16px;
                }
            }
        }
    }
}

#awards-container {
    h3 {
        font-size: 2.2rem;
        color: var(--text-dark);
        margin-bottom: 15px;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }

    h4 {
        font-size: 1.1rem;
        color: var(--text-regular);
    }

    .award-images-container {
        margin: 15px 0 18px 0;
        text-align: center;

        img {
            margin: 10px 5px;
            transition: transform .2s ease;
        }

        img:hover {
            transform: scale(1.05);
        }

        img:nth-of-type(3) {
            position: relative;
            top: -9px;
        }
    }
}

#prof-dev-container {
    margin-top: 10px;
    margin-bottom: 10px;

    h3 {
        font-size: 2.2rem;
        color: var(--text-dark);
        margin-bottom: 15px;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }

    h4 {
        font-size: 1.1rem;
        color: var(--text-regular);
    }
}

#bottom-register-container {
    .btn {
        font-size: 1.1em;
        font-weight: 600;
        padding: 15px 70px;
    }

    p {
        font-size: 1.15rem;
        color: var(--text-regular);
    }
}

#announcements {
    &.carousel {
        background: var(--background-gray-light);
    }

    .indicators-container {
        width: 250px;
        margin: 0 auto;
        position: relative;
    }

    .carousel-control-prev, .carousel-control-next {
        top: -38px;
        z-index: 500;
    }

    .carousel-control-prev {
        left: 35px;
    }

    .carousel-control-next {
        right: 35px;
    }

    .carousel-control-next-icon {
        width: 15px;
        height: 15px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2378716c' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
    }

    .carousel-control-prev-icon {
        width: 15px;
        height: 15px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2378716c' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
    }

    .carousel-indicators {
        margin-bottom: 5px;

        li {
            background-color: var(--gray-700);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            list-style: none;

            box-shadow:
                inset 0 1px 1px rgba(0,0,0,0.5), /*bottom internal shadow*/
                inset 0 -1px 1px rgba(255,255,255,0.8); /*top internal highlight*/
        }
    }

    .carousel-item .container {
        @media (max-width: 1199px) {
            width: 100%;
            max-width: 100%;
        }

        @media (min-width: 1200px) {
            position: relative;
            left: -40px;
        }
    }

    .carousel-item {
        .row {
            padding: 12px;

            @media (min-width: 0) {
                height: 300px;
            }

            @media (min-width: 576px) {
                height: 200px;
            }

            @media (min-width: 992px) {
                height: 320px;
            }

            .content .copy p:last-child {
                margin-bottom: 0;
            }
        }

        .image-column {
            text-align: center;
            padding: 0;

            @media (min-width: 0) {
                width: 75px;
                height: 75px;
            }

            @media (min-width: 992px) {
                width: 200px;
                height: 200px;
                margin-left: 10px;
                margin-right: 10px;
            }

            @media (min-width: 1200px) {
                width: 280px;
                height: 280px;
                margin-top: 4px;
            }

            .resource-thumbnail {
                border: 0;
                position: relative;
                z-index: 1000;

                .show-preview-label {
                    background: none;
                    color: var(--text-regular);
                    font-size: .75rem;
                    position: absolute;
                    z-index: 500;
                    bottom: -17px;

                    span {
                        left: -1px;
                    }

                    &:hover {
                        color: var(--text-dark) !important;
                    }
                }
            }
        }

        .content {
            @media (min-width: 0) {
                width: auto;
            }

            @media (min-width: 992px) {
                width: calc(100% - 320px);
            }

            @media (min-width: 1200px) {
                width: calc(100% - 260px);
            }

            h2 {
                color: var(--text-dark);
                font-family: "ChunkFiveRegular";
                font-weight: 500;
                font-size: 1.3rem;
                line-height: 1.7rem;

                @include media-breakpoint-up(lg) {
                    font-size: 1.7rem;
                    line-height: 1.9rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 2.1rem;
                    line-height: 2.4rem;
                }
            }

            .copy {
                color: var(--text-regular);
            }
        }
    }

    .carousel-item .background {
        opacity: 0.06;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;

        @media (min-width: 0) {
            top: 0;
        }

        @media (min-width: 576px) {
            top: -25%;
        }

        @media (min-width: 992px) {
            top: -50%;
            animation: slide-left-zoom 300s ease-out;
        }
    }
}

@keyframes slide-left-zoom {
    from {
        transform: translateX(0) translateY(0) scale(1);
    }

    to {
        transform: translateX(-187%) translateY(140%) scale(5);
    }
}
