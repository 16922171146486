.navbar.navbar-light {
    background: var(--econlowdown-color);
    box-shadow: 0 0.11rem 0.05rem rgba(0, 0, 0, 0.075);
    z-index: 2950;
    padding: 0.95rem 1rem;
    position: sticky;
    top: 0;    
    
    @media (max-width: $small-navbar-breakpoint) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .collapsing {
        transition: height .11s ease;
    }

    .container-fluid {
        max-width: 1300px;
    }

    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0.5rem;
        margin-right: 0;
        width: 132px;

        img {
            width: 100%;
        }

        @media (min-width: $small-navbar-breakpoint) {
            width: 200px;
        }
    }

    .navbar-right-items {
        position: relative;
        top: 1px;

        .btn {
            font-size: .8rem;
            margin-left: 2px;
            font-weight: 600;

            @media (min-width: 415px) {
                font-size: .9em;
                margin-left: 5px;
            }
        }

        .dropdown-menu[data-bs-popper] {
            left: inherit;
        }
    }

    .navbar-nav {
        padding-left: 2rem;

        @include media-breakpoint-up(lg) {
            .nav-item:hover .nav-link {
                color: var(--bs-secondary);
                text-decoration: underline;
            }
        }

        @include media-breakpoint-down(lg) {
            padding: 20px 20px 16px 20px;

            .nav-item {
                &:hover {
                    background: var(--bs-primary);
                    border-radius: 4px;

                    a {
                        color: var(--text-white);
                    }
                }

                a, .btn-link {
                    padding: 10px 20px;
                }

            }
        }
    }

    .nav-link {
        color: rgba(var(--text-dark-rgb), .90);
        font-weight: 600;
        font-size: 1rem;
        
        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .navbar-toggler-icon {
        background-image: none;
    }
}
