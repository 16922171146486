@import 'variables';

:root {
    --econlowdown-color: #{$econlowdown-color};
    --link-color: #{$link-color};
    --link-green: #{$link-green};
    --form-feedback-invalid-color: #{$form-feedback-invalid-color};
    --form-feedback-invalid-color-rgb: #{hexToRGB($form-feedback-invalid-color)};
    --form-feedback-invalid-text-color: #{$form-feedback-invalid-text-color};
    --input-focus-width: #{$input-focus-width};
    --input-focus-box-shadow: #{$input-focus-box-shadow};
    --input-focus-border-color: #{$input-focus-border-color};

    --text-dark: #{$text-dark};
    --text-dark-rgb: #{hexToRGB($text-dark)};
    --text-regular: #{$text-regular};
    --text-regular-rgb: #{hexToRGB($text-regular)};
    --text-light: #{$text-light};
    --text-light-rgb: #{hexToRGB($text-light)};
    --text-white: #{$text-white};
    --text-white-rgb: #{hexToRGB($text-white)};

    --spinner-color: #{$indigo};

    --notice-font-weight: #{$notice-font-weight};

    --highlight-background: #{$highlight};
    --background-white: #{$background-white};
    --background-white-rgb: #{hexToRGB($background-white)};
    --background-gray-light: #{$background-gray-light};
    --background-gray-light-rgb: #{hexToRGB($background-gray-light)};
    --border-gray-light: #{$border-gray-light};
    --border-gray-light-rgb: #{hexToRGB($border-gray-light)};
    --background-gray-medium: #{$background-gray-medium};
    --background-gray-medium-rgb: #{hexToRGB($background-gray-medium)};
    --border-gray-medium: #{$border-gray-medium};
    --background-gray-dark: #{$background-gray-dark};
    --background-gray-dark-rgb: #{hexToRGB($background-gray-dark)};
    --border-gray-dark: #{$border-gray-dark};
    --border-gray-dark-rgb: #{hexToRGB($border-gray-dark)};
    --background-black: #{$background-black};
    --background-black-rgb: #{hexToRGB($background-black)};
    --background-blue: #{$background-blue};
    --background-blue-bright: #{$background-blue-bright};
    --background-orange: #{$orange};

    --modal-close-color: #{$modal-close-color};
    --modal-backdrop-background: #{rgba($modal-backdrop-bg, $modal-backdrop-opacity)};

    --slate-100: #{$slate-100};
    --slate-200: #{$slate-200};
    --slate-300: #{$slate-300};
    --slate-400: #{$slate-400};
    --slate-500: #{$slate-500};
    --slate-600: #{$slate-600};
    --slate-700: #{$slate-700};
    --slate-800: #{$slate-800};
    --slate-900: #{$slate-900};
    --slate-100-rgb: #{hexToRGB($slate-100)};
    --slate-200-rgb: #{hexToRGB($slate-200)};
    --slate-300-rgb: #{hexToRGB($slate-300)};
    --slate-400-rgb: #{hexToRGB($slate-400)};
    --slate-500-rgb: #{hexToRGB($slate-500)};
    --slate-600-rgb: #{hexToRGB($slate-600)};
    --slate-700-rgb: #{hexToRGB($slate-700)};
    --slate-800-rgb: #{hexToRGB($slate-800)};
    --slate-900-rgb: #{hexToRGB($slate-900)};

    --blue-50: #{$blue-50};
    --blue-100: #{$blue-100};
    --blue-200: #{$blue-200};
    --blue-300: #{$blue-300};
    --blue-400: #{$blue-400};
    --blue-500: #{$blue-500};
    --blue-600: #{$blue-600};
    --blue-700: #{$blue-700};
    --blue-800: #{$blue-800};
    --blue-900: #{$blue-900};
    --blue-100-rgb: #{hexToRGB($blue-100)};
    --blue-200-rgb: #{hexToRGB($blue-200)};
    --blue-300-rgb: #{hexToRGB($blue-300)};
    --blue-400-rgb: #{hexToRGB($blue-400)};
    --blue-500-rgb: #{hexToRGB($blue-500)};
    --blue-600-rgb: #{hexToRGB($blue-600)};
    --blue-700-rgb: #{hexToRGB($blue-700)};
    --blue-800-rgb: #{hexToRGB($blue-800)};
    --blue-900-rgb: #{hexToRGB($blue-900)};

    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
    --gray-900: #{$gray-900};
    --gray-100-rgb: #{hexToRGB($gray-100)};
    --gray-200-rgb: #{hexToRGB($gray-200)};
    --gray-300-rgb: #{hexToRGB($gray-300)};
    --gray-400-rgb: #{hexToRGB($gray-400)};
    --gray-500-rgb: #{hexToRGB($gray-500)};
    --gray-600-rgb: #{hexToRGB($gray-600)};
    --gray-700-rgb: #{hexToRGB($gray-700)};
    --gray-800-rgb: #{hexToRGB($gray-800)};
    --gray-900-rgb: #{hexToRGB($gray-900)};

    --green-100: #{$green-100};
    --green-200: #{$green-200};
    --green-300: #{$green-300};
    --green-400: #{$green-400};
    --green-500: #{$green-500};
    --green-600: #{$green-600};
    --green-700: #{$green-700};
    --green-800: #{$green-800};
    --green-900: #{$green-900};
    --green-100-rgb: #{hexToRGB($green-100)};
    --green-200-rgb: #{hexToRGB($green-200)};
    --green-300-rgb: #{hexToRGB($green-300)};
    --green-400-rgb: #{hexToRGB($green-400)};
    --green-500-rgb: #{hexToRGB($green-500)};
    --green-600-rgb: #{hexToRGB($green-600)};
    --green-700-rgb: #{hexToRGB($green-700)};
    --green-800-rgb: #{hexToRGB($green-800)};
    --green-900-rgb: #{hexToRGB($green-900)};

    --red-100: #{$red-100};
    --red-200: #{$red-200};
    --red-300: #{$red-300};
    --red-400: #{$red-400};
    --red-500: #{$red-500};
    --red-600: #{$red-600};
    --red-700: #{$red-700};
    --red-800: #{$red-800};
    --red-900: #{$red-900};
    --red-100-rgb: #{hexToRGB($red-100)};
    --red-200-rgb: #{hexToRGB($red-200)};
    --red-300-rgb: #{hexToRGB($red-300)};
    --red-400-rgb: #{hexToRGB($red-400)};
    --red-500-rgb: #{hexToRGB($red-500)};
    --red-600-rgb: #{hexToRGB($red-600)};
    --red-700-rgb: #{hexToRGB($red-700)};
    --red-800-rgb: #{hexToRGB($red-800)};
    --red-900-rgb: #{hexToRGB($red-900)};

    --primary: #{$primary};
    --primary-darkened: #{$primary-darkened};
    --secondary: #{$secondary};
    --info: #{$info};
    --success: #{$success};
    --danger: #{$danger};
    --warning: #{$warning};
}
