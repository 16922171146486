#error-modal {
  max-width: 630px;
  height: 200px;
  overflow: hidden;
  transform: none;
  z-index: 4000;

  background: rgba(var(--background-black-rgb), .97);
  border-radius: 20px;

  @include media-breakpoint-down(lg) {
      margin: auto;

      .container-fluid {
          padding-right: 0;
          padding-left: 0;

          .row {
              margin-right: 0;
              margin-left: 0;
          }
      }
  }

  @include media-breakpoint-down(md) {
      max-width: calc(100% - 20px);
      border-radius: 10px;
  }
  
  .content-container {
      position: relative;
      padding: 0;
      color: var(--text-white);
      text-align: center;

      .error-message-container {
          margin: 16px 25px;
          
          .exclamation-circle {
              fill: var(--bs-warning);
              position: relative;
              width: 35px;
              height: 35px;
              top: -3px;
              left: -8px;
          }

          .error-message {
              font-size: 1.3rem;
              font-weight: 600;
              position: relative;
              left: 4px;
          }

          @include media-breakpoint-down(md) {
              .exclamation-circle {
                  width: 20px;
                  height: 20px;
                  top: -2px;
                  left: -4px;
              }

              .error-message {
                  font-size: 1.1rem;
              }
          }
      }

      .btn {
          width: 80px;
          font-size: 1.2rem;
          margin: 0 25px;

          @include media-breakpoint-down(md) {
              font-size: 1rem;
          }
      }
  }

  &.display {
      display: flex !important;
  }

  &.show {
      animation-duration: .5s;
      animation-fill-mode: both;
      animation-name: jello;
      transform-origin: center;
  }
}

#error-modal + .custom-modal-backdrop {
    z-index: 3900;
}

@keyframes jello {
    from,
    11.1%,
    to {
        ransform: translate3d(0, 0, 0);
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
