.resource-thumbnail {
    position: relative;
    border-radius: 6px;

    .thumbnail-container {
        cursor: pointer;
        transition: transform .2s;
        border-radius: 6px;
        position: relative;
        padding-bottom: 100%;

        &.pulse {
            animation: pulse 1s ease;
        }

        @media (min-width: 576px) {
            &.pulse-sm {
                animation: pulse-sm .95s ease;
            }
        }

        &.grow:hover {
            transform: scale3d(1.05, 1.05, 1.05);
        }
    }

    .image {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        
        &::before {
            background-color: var(--background-white);
            border-radius: 6px;
            color: var(--text-white);
            content: "";
            display: block;
            height: calc(100% + 10px);
            left: 0px;
            position: absolute;
            top: -10px;
            width: 100%;
        }
    }

    .image-overlay {
        display: flex;
        border-radius: 6px;
        position: absolute;
        z-index: 1000;
        top: 0;
        color:var(--text-white);
        background: rgba(var(--slate-500-rgb), 0.5);
        width: 100%;
        height: 100%;
        align-items: center;
        opacity: 0;
        transition: opacity .25s;

        .action {
            margin: 0 auto;
            text-align: center;

            svg {
                display: block;
                width: 40%;
                height: 40%;
                min-width: 45px;
                min-height: 45px;
                margin: 0 auto;
            }
        }

        &:hover {
            opacity: 1;
        }
    }

    .show-preview-label {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 16px;
        background: var(--gray-700);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        color: var(--text-white);
        font-size: .7rem;
        line-height: .9rem;
        text-align: center;

        span {
            position: relative;
            top: 1px;
            left: -5px;

            svg {
                width: 13px;
                height: 13px;
                position: relative;
                right: 0px;
                top: -1px;  
                margin-right: 1px;
            }
        }
    }
}

.show-preview-label {
    cursor: pointer;
}

.shadow-xs {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1) rotate(-5deg);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
}

@keyframes pulse-sm {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}


.thumbnail-container.loading::before {
    content: url(/images/svg-icons/image-placeholder.svg);
    filter: brightness(1.8);
    height: 60px;
    left: calc(50% - 30px);
    position: absolute;
    top: calc(50% - 30px);
    width: 60px;
}

.resource:not(.selected) img {
    transition: opacity 1s;

    &.lazyload,
    &.lazyloading {
        opacity: 0;  
    }

    &.lazyloaded {
        opacity: 1;
    }
}
