.navbar.navbar-light {
    .navbar-right-items .btn.register-button span {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .login-link-container {
        font-size: 0.8em;

        @media (min-width: 350px) {
            font-size: 1em;
        }

        font-weight: 400;
        color: var(--text-dark);
        position: relative;
        top: 5px;
        left: -10px;

        span {
            vertical-align: middle;
        }
    }

    .student-login-container {
        .hint {
            color: var(--text-dark);
            opacity: .9;
            position: relative;
            top: 2px;
            left: -4px;

            @media (min-width: 0) {
                display: none;
            }

            @media (min-width: 700px) {
                display: inline;
            }
        }
    }

    .instructor-login-container {
        display: none;

        @media (max-width: 514px) {
            display: none !important;
        }
    }
}

.info-menu {
    bottom: 0.4rem;
    padding-left: 0.5rem;
    
    .info-icon {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        
        color: var(--text-white);
        border: none;
        border-radius: 50%;
        text-align: center;
        
        img {
            border-radius: 20%;
            height: 1.5rem;
            width: 1.5rem;
        }
        
        &.user-icon {
            background-color: var(--bs-secondary);
        }
        
        &.alert-icon {
            background-color: var(--background-gray-medium);
        }
        
        &.alert-icon.has-actions {
            background-color: var(--background-orange);
        }
    }
    
    .info-icon-large {
        width: 3.5rem;
        height: 3.5rem;
        padding-top: 0.9rem;
        
        img {
            height: 2rem;
            width: 2rem;
        }
    }
    
    .info-panel {
        width: 20rem;
        right: -0.5rem;

        .mobile-close-button {
            .close-button {
                left: 0;
                top: -10px;

                &:before,
                &:after {
                    height: 20px;
                }
            }
        }

        .close-button-container {
            position: absolute;
            top: -7px;
            right: 0;
            font-size: 2rem;
            font-weight: 600;
            color: var(--modal-close-color);
            cursor: pointer;
            z-index: 100;
            
            &:hover {
                font-weight: 700;
            }
        }
    }
    
    .info-details {
        font-size: 0.9rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        line-height: 1.4rem;
        
        hr {
            margin: 0.5rem 0;
        }
    }
    
    .instructor-name {
        font-weight: bold;
        font-size: 1rem;
    }
    
    .instruction-icon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.2rem;
        filter: invert(26%) sepia(43%) saturate(6229%) hue-rotate(188deg) brightness(97%) contrast(102%);
    }
}

#alert-menu,
#account-menu {
    .dropdown-menu {
        padding: 0;
    }
    .info-panel {
        max-height: 80vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        width: 25rem;
    }
}

#alert-menu .info-panel .card-header {
    background-color: var(--secondary);
    color: var(--text-white);
}

#alert-menu.info-menu .info-panel .close-button-container {
    color: var(--text-white);
}

#account-menu .info-panel {
    width: 20rem;

    .menu-container {
        padding: 1.5rem 1rem;
    }
}

@media (max-width: $small-navbar-breakpoint) {
    .info-menu button.info-icon {
        width: 2rem;
        height: 2rem;

        img {
            height: 1.3rem;
            width: 1.3rem;
        }
    }

    #alert-menu .info-panel,
    #account-menu .info-panel {
        width: 100vw;
        max-height: 100vh;
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        border: none;
        border-radius: 0;
        overflow: hidden;

        .info-details {
            ul {
                height: calc(100vh - 240px);
                margin: 0;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }
        }

        .menu-container {
            width: 100vw;
            height: 100vh;
            max-height: 100vh;
        }
    }
}
