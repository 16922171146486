.was-validated {
    .form-control {
        &:valid,
        &.is-valid {
            background: $input-bg;
            border: $border-width solid $border-color;
        }

        &:valid:focus, &.is-valid:focus {
            box-shadow: var(--input-focus-box-shadow);
            border-color: var(--input-focus-border-color);
        }

        &:invalid, &.is-invalid {
            border-color: var(--form-feedback-invalid-color);
            box-shadow: 0 0 0 var(--input-focus-width) rgba(var(--form-feedback-invalid-color-rgb), .85);

            &:focus {
                border-color: var(--form-feedback-invalid-color);
                box-shadow: 0 0 0 var(--input-focus-width) rgba(var(--form-feedback-invalid-color-rgb), 1);
            }
        }
    }

    .form-control ~ .is-invalid,
    .form-control ~ .invalid-feedback {
        color: var(--form-feedback-invalid-text-color);
    }

    .input-group .form-control ~ .is-invalid,
    .input-group .form-control ~ .invalid-feedback {
        width: 100%;
    }

    .form-check-input {
        &:valid,
        &.is-valid {
            border-color: $border-color;

            &:focus {
                border-color: var(--input-focus-border-color);
                box-shadow: var(--input-focus-box-shadow);
            }
        }

        &:valid:checked,
        &.is-valid:checked {
            background-color: var(--secondary);
            border-color: var(--secondary);

            &:focus {
                border-color: var(--secondary);
                box-shadow: var(--input-focus-box-shadow);
            }
        }

        &:invalid,
        &.is-invalid {
            border-color: var(--form-feedback-invalid-color);
            box-shadow: 0 0 0 var(--input-focus-width) rgba(var(--form-feedback-invalid-color-rgb), .85);
            margin-right: 4px;

            &:focus {
                border-color: var(--form-feedback-invalid-color);
                box-shadow: 0 0 0 var(--input-focus-width) rgba(var(--form-feedback-invalid-color-rgb), 1);
            }
        }
    }

    .form-select:valid,
    .form-select.is-valid {
        border-color: $form-select-border-color;

        &:focus {
            border-color: $form-select-border-color;
            box-shadow: var(--input-focus-box-shadow);
        }
    }

    &.hide-feedback {
        .form-control ~ .invalid-feedback {
            display: none;
        }
    }
}

.redactor-modal-box,
.redactor-dropdown {
    z-index: 2000;
}

.redactor-box.redactor-styles-on {
  border: 1px solid var(--border-gray-medium);

  &.redactor-focus {
    box-shadow: var(--input-focus-box-shadow);
    border-color: var(--input-focus-border-color);
  }

}

.form-check.form-check-lg {
    display: flex;
    align-items: center;

    label {
        margin-left: 0.6rem;
    }

    input {
        flex-shrink: 0;
        height: 1.8rem;
        width: 1.8rem;
        margin-top: 0;
    }
}

.form-check.form-check-md {
    display: flex;
    align-items: center;

    label {
        margin-left: 0.6rem;
    }

    input {
        flex-shrink: 0;
        height: 1.2rem;
        width: 1.2rem;
        margin-top: 0;
    }
}

.form-switch.form-switch-lg {
    font-size: 1.4rem;

    label {
        font-size: 1rem;
    }

    input {
        margin-top: 0.6rem;
    }
}

.btn-light {
    border: $btn-border-width solid $background-gray-medium;

    &:hover {
        border-color: $border-gray-medium;
    }
}
