.newsletter-popup {
    display: block !important;
    position: fixed;
    z-index: 2900;
    pointer-events: none;

    background: var(--bs-secondary);
    color: var(--text-white);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.2);
    transform: translateY(10%);
    opacity: 0;

    padding-top: 0.5em;
    padding-bottom: 12px;

    width: 100%;
    bottom: 0;

    h5 {
        color: inherit;
    }

    .close-button {
        position: absolute;
        right: 4px;
        top: 4px;
        color: var(--text-white);
        cursor: pointer;
        background-color: var(--gray-400);
        border-radius: 50%;
        width: 30px;
        text-align: center;
        height: 30px;
        z-index: 100;

        &::after {
            content: "×";
            font-size: 30px;
            font-weight: 700;
            height: 30px;
            width: 30px;
            line-height: 30px;
        }
    }

    @include media-breakpoint-up(sm) {
        width: 396px;
        bottom: 20px;
        right: 20px;

        border-radius: 8px;
        overflow: visible;

        .close-button {
            right: -14px;
            top: -14px;
        }
    }

    .content-container {
        height: 100%;
    }

    .success-message {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 40%;
        left: 0;
        font-size: 1.8rem;
        font-weight: 700;
        transition: opacity .4s ease, font-size .4s ease;
    }

    .success-message.show {
        opacity: 1;
        font-size: 1.6rem;
    }

    .invalid-feedback {
        display: block;
        width: calc(100% + 20px);
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: -60px;
        font-size: .9rem;
        font-weight: 700;
        background: var(--background-white);
        padding: 4px 10px;
        border-radius: 4px;
        transition: opacity .2s ease;

        @media (min-width: 406px) {
            top: -50px;
        }
    }

    .flash-error.show {
        opacity: 1;
    }

    .form-container {
        max-width: 576px;
        margin: 0 auto;
        transition: opacity .4s ease;

        &.hide {
            opacity: 0;
        }

        h5 {
            font-size: 1.1rem;
            font-weight: 600;
        }

        p {
            font-size: .8rem;
            margin-top: 7px;
            margin-bottom: 12px;
            transition: opacity .1s ease;
        }

        p.hide {
            opacity: 0;
        }

        form {
            input {
                font-size: .8rem;
            }

            .submit-container {
                @media (max-width: 405px) {
                    padding: 0;
                }
            }

            .btn {
                font-size: .8rem;
            }

            &.was-validated .invalid-feedback {
                opacity: 1;
            }
        }
    }

    &.show {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0%);
    }
}
