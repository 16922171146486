@import "../../common/sass/common";

@import '../../common/sass/custom-modal';
@import 'details-preview';
@import 'login-form';
@import 'login-modal';
@import 'navbar';
@import 'resource-thumbnail';
@import 'announcement-thumbnail';
@import 'error-modal';

body.syllabus-embed {
    padding-top: 0;
}

hr.custom-hr {
    border: 2px solid var(--gray-100);
    border-radius: 6px;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
}

h1 {
    font-size: 1.5rem;
}

h2, h3 {
    font-family: "ChunkFiveRegular";
    font-weight: 500;
}

.hint {
    font-size: .9rem;
}

.container {
    @media (min-width: 768px) and (max-width: 991px) {
        max-width: 900px;
    }
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 0.1rem;
    animation: spinner-border .75s cubic-bezier(0.25, 0.07, 0.14, 0.79) infinite;
    position: relative;
    top: -2px;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.outline-none {
    outline: 0;
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

#page-content {
    position: relative;
    min-height: 60vh;
}

.form-check {
    padding-left: 0.7rem;

    .form-check-input {
        margin-left: 0;
    }

    &.d-flex .form-check-input {
        flex-shrink: 0;
    }

    input {
        // Fix broken rendering of custom checkbox states on iOS devices
        z-index: 0;
        cursor: pointer;
        width: 1.4rem;
        height: 1.4rem;
        margin-top: 0;
    }

    input:checked {
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
    }

    .form-check-label {
        vertical-align: middle;
        font-size: 1.1rem;
        padding: 2px 0.6rem;
    }

    .form-check-input:checked ~ .form-check-label::before {
        color: var(--text-white);
        border-color: var(--bs-primary);
        background-color: var(--bs-primary);
    }

    cursor: pointer;

    label {
        cursor: pointer;
        width: 100%;
    }

    &:hover {
        background: var(--background-gray-light);
    }

    &.form-switch:hover {
        background: inherit;
    }
}

.popover {
    --bs-popover-bg: var(--background-black);

    z-index: 3060;

    .popover-body {
        color: var(--text-white);
        border-radius: 5px;
        padding: 15px 20px;

        .close-popover {
            color: var(--text-white);
            cursor: pointer;
            float: right;
            font-size: 2rem;
            line-height: 2rem;
        }

        h4 {
            color: var(--text-white);
            font-size: 1.3rem;
        }

        p {
            font-size: 0.9rem;
        }

        p:last-child {
            margin-bottom: 0;
        }

        a {
            color: var(--text-white);
            text-decoration: underline var(--blue-300) 2px;
            font-weight: bold;

            &:hover {
                text-decoration-thickness: 3px;
            }
        }
    }
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[x-placement^=top] > .popover-arrow::after {
    border-top-color: var(--background-black);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[x-placement^=right] > .popover-arrow::after {
    border-right-color: var(--background-black);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[x-placement^=bottom] > .popover-arrow::after {
    border-bottom-color: var(--background-black);
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[x-placement^=left] > .popover-arrow::after {
    border-left-color: var(--background-black);
}
