.login-modal {
    max-width: 576px;
    max-height: 510px;

    @include media-breakpoint-down(lg) {
        max-width: 100%;
        max-height: 100%;

        .container-fluid {
            padding-right: 0;
            padding-left: 0;

            .row {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .student-login-container {
        padding: 18px 8px 9px 12px;

        .hint {
            font-size: .7rem;
            color: var(--text-dark);
            margin: 4px;
        }

        .btn {
            font-size: .7rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 0 12px 0;
        }
    }

    .content-container {
        height: 100%;
        overflow-y: scroll;
    }

    .form-container {
        max-width: 576px;
        margin: 0 auto;
        padding: 20px 30px;

        h5 {
            margin-bottom: 30px;
            font-weight: 600;
        }
    }

    .flash-message {
        display: block !important;
        opacity: 0;
        font-size: .9rem;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 22px;
        height: 0;

        transition: all .4s ease;

        @include media-breakpoint-up(lg) {
            border-radius: 7px;
        }
    }

    .flash-message.show {
        opacity: 1;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: -8px;
        height: auto;
    }

    .forgot-password-link-container {
        margin-top: 25px;
    }

    .card-sub-header {
        color: var(--text-dark);
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.3rem;
    }

    #google-login-button iframe {
        margin: 0 auto !important;
    }
}
