.custom-modal {
    display: none;
    position: fixed;
    z-index: 3000;
    background: var(--background-white);
    pointer-events: none;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.2);
    transform: translateY(10%);
    opacity: 0;

    @include media-breakpoint-up(lg) {
        margin: auto;
        width: calc(100% - 80px);
        height: calc(100% - 80px);
        padding-top: 1em;
        padding-bottom: 1em;
        border-radius: 8px;
        overflow: visible;

        .desktop-close-button {
            position: absolute;
            right: 8px;
            top: 0;
            color: var(--modal-close-color);
            cursor: pointer;
            z-index: 100;

            &::after {
                content: "×";
                font-size: 31px;
                font-weight: 700;
                top: -10px;
                position: absolute;
                right: 7px;
            }
        }
    }

    &.display {
        display: block !important;
    }

    &.show {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0%);
    }
}

.custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-backdrop-background);
    pointer-events: none;
    z-index: 2950;
    opacity: 0;

    transition: opacity 0.3s;
}

.custom-modal.show + .custom-modal-backdrop {
    opacity: 1;
    pointer-events: auto;
}

.mobile-close-button {
    position: absolute;
    right: 16px;
    cursor: pointer;

    top: 24px;

    @include media-breakpoint-up(md) {
        top: 18px;
    }

    .close-button {
        width: 41px;
        height: 41px;
        position: relative;
        left: -12px;

        &:before, &:after {
            content: '';
            display: inline-block;
            
            background-color: var(--modal-close-color);
            border-radius: 10px;
            position: relative;

            height: 25px;
            width: 5px;

            @include media-breakpoint-up(md) {
                height: 36px;
                width: 8px;
            }
        }

        &:before {
            left: 18px;
            top: -4px;
            transform: translateX(16px) rotate(-45deg);

            @include media-breakpoint-up(md) {
                left: 16px;
            }
        }

        &:after {
            top: -4px;
            transform: translateX(-16px) rotate(45deg);

            left: 45px;

            @include media-breakpoint-up(md) {
                left: 40px;
            }
        }
    }
}
