footer {
    background: var(--background-gray-light);
    .footer-content {
        max-width: 1318px;
        section {
            color: var(dark-text);
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                padding-left: 13px;
                border-left: 2px solid rgba(0, 0, 0, 0.1);
                li {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    h6 {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    section ul.social {
        a:link {
            transition: all 0.2s ease-in-out;
        }
        a:link:hover {
            transform: scale(1.05);
        }
        svg {
            width: 30px;
            height: 30px;
        }
    }
}
