.announcement-thumbnail {
    position: relative;
    transition: transform .2s;

    &.pulse {
        animation: pulse 1s ease;
    }

    @media (min-width: 576px) {
        &.pulse-sm {
            animation: pulse-sm .95s ease;
        }
    }

    .image {
        border-radius: 6px;
        width: 100%;
        height: 100%;
    }
}
