body.resource-details {
    padding: 0;
}

#details-preview {
    .spinner-container {
        position: fixed;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        pointer-events: none;
        opacity: 0;

        transition: opacity .3s;

        .spinner-border {
            width: 3rem;
            height: 3rem;
            color: var(--spinner-color);
        }
    }

    #details-pane,
    #preview-pane {
        overflow-y: hidden;

        &.loading > * {
            opacity: 0;

            &.spinner-container {
                opacity: 1;
            }
        }

        .resource-details {
            min-height: 150px;
        }
    }

    .content-container {
        opacity: 1;
        transition: opacity .3s;
    }

    .tabs-container {
        background: var(--background-gray-light);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1020;
        width: 100%;
    }

    .nav-tabs {
        width: calc(100% - 75px);
        text-align: center;
        border: 0;

        .nav-item {
            width: 50%;

            .nav-link {
                color: var(--text-dark);
                padding-top: 22px;
                padding-bottom: 18px;
                border: none;
                border-radius: 0;

                font-size: .9rem;

                @include media-breakpoint-up(md) {
                    font-size: 1rem;
                }

                &.active {
                    background: var(--background-gray-light);
                    border-bottom: 2px solid var(--bs-secondary);
                    font-weight: bold;
                }
            }
        }
    }

    .content-container, .tab-content, .tab-pane, iframe {
        width: 100%;
        height: 100%;
    }

    .tab-content {
        padding: 0;
    }

    .tab-pane {
        padding-top: 65px;

        @include media-breakpoint-up(lg) {
            padding: 0;
            display: block;
        }

        iframe {
            border: 0;
        }
    }

    #details-pane {
        padding: 85px 15px 15px 15px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        @include media-breakpoint-up(lg) {
            padding: 15px 15px 0 15px;
        }
    }

    #details-content {
        height: calc(100% - 15px);
    }

    #preview-pane {
        @supports (-webkit-overflow-scrolling: touch) {
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }

        @include media-breakpoint-up(lg) {
            padding: 15px 0 15px 15px;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-down(lg) {
        .container-fluid {
            padding-right: 0;
            padding-left: 0;

            .row {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .thumbnail-column {
        width: 90px;
        padding-right: 0;
        opacity: 1;
        transition: opacity 1s ease-out;

        .select-toggle + #details-preview-thumbnail {
            cursor: pointer;
        }

        &.resource.selected {
            border-radius: 8px;
            background: none;

            #details-preview-thumbnail {
                border: 4px solid var(--blue-400);
            }

        }
    }

    #details-preview-thumbnail {
        width: 75px;
        height: 75px;
        border-radius: 5px;
    }

    #details-preview-title {
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.4rem;
        margin-top: 0.9rem;
        margin-bottom: 0.9rem;
    }

    #link-column {
        label {
            margin-bottom: 0;
            margin-left: 2px;
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--text-regular);
        }

        #shareable-preview-link {
            max-width: 260px;

            #link-input {
                font-size: .75rem;
                height: 1.8rem;
                padding-left: 8px;
                transition: background .25s;
            }

            .copy-link-button {
                background: var(--bs-primary);
                color: var(--text-white);
                font-size: .75rem;
                width: 60px;
                line-height: 1rem;
                transition: background .2s;

                &:focus {
                    box-shadow: none;
                }

                svg {
                    fill: var(--text-white);
                    width: 12px;
                    height: 12px;
                    position: relative;
                    top: -1px;
                    left: 1px;
                }

                .text-copy, .text-copied {
                    position: absolute;
                    transition: opacity .2s;
                }

                .text-copy {
                    top: 5px;
                    left: 9px;
                }

                .text-copied {
                    opacity: 0;
                    left: 20px;
                    top: 5px;

                    svg {
                        transform: rotate(-50deg);
                        transition: all .35s;
                    }
                }
            }

            &.copied {
                #link-input {
                    background: var(--gray-200);
                }

                .copy-link-button {
                    background: var(--green-600);
                    
                    .text-copy {
                        opacity: 0;
                    }

                    .text-copied {
                        opacity: 1;

                        svg {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }

    .resource-attributes {
        color: var(--text-regular);
        font-weight: 600;
        font-size: .9rem;

        .duration-icon,
        .details-preview-type-icon {
            height: 23px;
        }

        img {
            height: 16px;
        }
    }

    #details-preview-subjects,
    #details-preview-topics {
        .label {
            color: var(--text-dark);
            font-weight: 600;
        }

        font-size: 0.8rem;
    }

    #details-preview-subjects {
        color: var(--bs-primary);
    }

    #details-preview-topics {
        color: var(--bs-secondary);
    }

    .teacher-only-content,
    .answer-key {
        margin-top: 0.5rem;
        font-size: 0.9em;
    }

    @include media-breakpoint-up(lg) {
        .col-lg-3 {
            max-width: 25%;
        }

        .col-lg-9 {
            max-width: 75%;
        }
    }
}
