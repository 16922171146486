@import 'includes';

@import 'css-variables';

@font-face {
    font-family: 'ChunkFiveRegular';
    src: url('/htdocs/css/fonts/Chunkfive-webfont.eot');
    src: url('/htdocs/css/fonts/Chunkfive-webfont.eot?#iefix') format('embedded-opentype'),
    url('/htdocs/css/fonts/Chunkfive-webfont.woff') format('woff'),
    url('/htdocs/css/fonts/Chunkfive-webfont.ttf') format('truetype'),
    url('/htdocs/css/fonts/Chunkfive-webfont.svg#ChunkFiveRegular') format('svg');
    font-weight: normal 500;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--text-dark);
}

.flash-message {
    font-size: 1rem;
    font-weight: var(--notice-font-weight);
    border-radius: 0;
    border: none;
    text-align: center;

    a {
        font-weight: bold;
    }
}

@import 'navbar';
@import 'navbar-toggler';
@import 'footer';
@import 'forms';
